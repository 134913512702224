import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './Menus.module.css'

export default function Banner({
  src,
  isLoading,
}: {
  src?: string
  isLoading?: boolean
}) {
  return (
    <div className={styles.bannerContainer}>
      {isLoading ? (
        <div className={styles.banner}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <div
          style={{ backgroundImage: `url(${src})` }}
          className={styles.banner}
        />
      )}
    </div>
  )
}
