import { useOrder } from '@Pacific-Web-Services/component-library'
import React from 'react'
import styles from './Checkout.module.css'

export default function ContactForm() {
  const { order, setCustomer } = useOrder()

  return (
    <div>
      <h3 className={styles.h3}>Contact Information</h3>
      <Input
        required
        className={styles.input}
        type="email"
        value={order.customer.email}
        onChange={(event) => setCustomer({ email: event.target.value })}
        placeholder="Email"
      />
      <Input
        required
        className={styles.input}
        type="tele"
        value={order.customer.phone}
        onChange={(event) => setCustomer({ phone: event.target.value })}
        placeholder="Phone"
      />
      <Input
        required
        className={styles.input}
        type="text"
        value={order.customer.name}
        onChange={(event) => setCustomer({ name: event.target.value })}
        placeholder="Full Name"
      />
    </div>
  )
}

const Input = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className={styles.inputContainer}>
      <input {...props} />
    </div>
  )
}
