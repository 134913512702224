import { useOrder } from '@Pacific-Web-Services/component-library'
import useMenus from '../Menus/useMenus'
import {
  menuToPickupDates,
  toPickupDateIntersections,
} from '../utils/menuReducer'

export default function usePickupDates() {
  const menus = useMenus()
  const { order } = useOrder()
  if (!menus) {
    return []
  }
  if (order.items.length) {
    const menuIds = order.items.map((i) => i.menuId)
    return menus
      .filter((m) => menuIds.includes(m.id))
      .reduce(toPickupDateIntersections, [])
  }
  return menus.filter((m) => !m.disabled).reduce(menuToPickupDates, [])
}
