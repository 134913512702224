import { useOrder } from '@Pacific-Web-Services/component-library'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import React from 'react'
import styles from './Checkout.module.css'

const stripeStyles = {
  style: {
    base: {
      color: 'black',
      fontFamily: `"Merriweather Sans", sans-serif`,
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(0,0,0,.6)',
      },
    },
  },
}

export default function PaymentForm() {
  const { order, setCustomer } = useOrder()
  return (
    <div>
      <h3 className={styles.h3}>Payment Information</h3>
      <div className={styles.inputContainer}>
        <CardNumberElement
          className={styles.input}
          options={{ ...stripeStyles, placeholder: 'Card Number' }}
        />
      </div>
      <div className={styles.inputContainer}>
        <CardExpiryElement
          className={styles.input + ' ' + styles.smallInput}
          options={stripeStyles}
        />
        <CardCvcElement
          className={styles.input + ' ' + styles.smallInput}
          options={stripeStyles}
        />
        <input
          className={styles.input + ' ' + styles.smallInput}
          maxLength={10}
          placeholder="Zip Code"
          value={order.customer.postalCode}
          onChange={(event) => setCustomer({ postalCode: event.target.value })}
          required
        />
      </div>
    </div>
  )
}
