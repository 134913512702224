import {
  QuantitySelector,
  useOrder,
} from '@Pacific-Web-Services/component-library'
import { ProductPublicDto } from 'product/dto/product-public.dto'
import React, { useMemo } from 'react'
import { getProductInventory } from '../utils/inventoryFunctions'
import useProducts from './useProducts'

export default function ModalSelector(props: {
  product: ProductPublicDto
  quantity: number
  setQuantity: (quantity: number) => void
}) {
  const { product, quantity, setQuantity } = props
  const products = useProducts()
  const { order } = useOrder()

  const canAddItem = useMemo(() => {
    let remainingInventory: number | undefined = 0
    const pendingItems = order.items.map((item) => {
      if (item.productId === product.id) {
        return { ...item, quantity }
      }
      return item
    })
    if (order.date) {
      remainingInventory = getProductInventory(
        product,
        order.date,
        pendingItems,
        products,
      )
    }
    const hasInventory = remainingInventory === undefined || remainingInventory
    const hasNotReachedMaximum =
      !product.maximumPerOrder || quantity < product.maximumPerOrder
    const canAddItem = hasInventory && hasNotReachedMaximum
    return canAddItem
  }, [order.items, order.date, product, products, quantity])

  const decrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1)
    }
  }
  const increase = () => {
    if (canAddItem) {
      setQuantity(quantity + 1)
    }
  }

  return (
    <QuantitySelector
      quantity={quantity}
      onClickDecrease={decrease}
      onClickIncrease={increase}
    />
  )
}
