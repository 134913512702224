import { TimeZone } from 'common/timezone/timezone'
import { DateTime } from 'luxon'
import { MenuPublicDto } from 'menu/dto/menu-public.dto'
import { SectionPublicDto } from 'menu/dto/section-public.dto'
import { ProductPublicDto } from 'product/dto/product-public.dto'
import { useMemo } from 'react'
import useMenus from '../Menus/useMenus'
import { getProductInventory } from './inventoryFunctions'
import { menuToProducts } from './menuReducer'
import { localTime } from './timeFunctions'

interface PizzaInventory {
  [key: string]: number
}

export function usePizzaInventory(
  date: string,
  zone: TimeZone,
): PizzaInventory {
  const menus = useMenus()
  return useMemo(() => {
    const pizzaMenu = menus?.find(isPizzaMenu)
    if (!pizzaMenu) {
      return {}
    }
    const pizza = menuToProducts([], pizzaMenu).find(productIsPizza)
    const pickupDate = pizzaMenu.pickupDates.find((d) => d.date === date)
    if (!pizza || !pickupDate) {
      return {}
    }
    const pizzaInventory: PizzaInventory = {}
    for (const time of pickupDate.times) {
      const pickupTime = DateTime.fromISO(`${date}T${time}`, { zone }).toISO()
      pizzaInventory[time] = getProductInventory(pizza, pickupTime) || 0
    }
    return pizzaInventory
  }, [menus, date, zone])
}

export function formatPizzaTime(
  time: string,
  remainingProduct: number,
  productType: string = 'pizza',
) {
  let productLeft: string
  if (remainingProduct <= 0) {
    productLeft = 'Sold Out'
  } else if (remainingProduct === 1) {
    productLeft = `1 ${productType} left`
  } else if (productType === 'sandwich') {
    productLeft = `${remainingProduct} ${productType}es left`
  } else {
    productLeft = `${remainingProduct} ${productType}s left`
  }
  return `${localTime(time)} - ${productLeft}`
}

const isPizzaMenu = (menu: MenuPublicDto) =>
  menu.sections.some(sectionContainsPizza)

const sectionContainsPizza = (section: SectionPublicDto) =>
  section.products.some(productIsPizza)

const productIsPizza = (product: ProductPublicDto) =>
  product.throughputs.some(
    (t) =>
      t.description === 'pizzas' ||
      t.description === 'tacos' ||
      t.description === 'breakfast sandwiches',
  )
