import {
  QuantitySelector,
  useOrder,
} from '@Pacific-Web-Services/component-library'
import { ProductPublicDto } from 'product/dto/product-public.dto'
import React, { useMemo } from 'react'
import useMenus from '../Menus/useMenus'
import { getProductInventory } from '../utils/inventoryFunctions'
import { pickupDateContainsISO } from '../utils/timeFunctions'
import useProducts from './useProducts'

export default function ProductSelector(props: {
  product: ProductPublicDto
  promptTimeSelect?: () => void
  onIncrease?: () => void
}) {
  const { promptTimeSelect, product, onIncrease } = props
  const menus = useMenus()
  const products = useProducts()
  const { order, addItemToOrder, removeItemFromOrder, updateOrderItem } =
    useOrder()

  const { item, index, quantity, canAddItem, hideSelectors } = useMemo(() => {
    const index = order.items.findIndex((i) => i.productId === product.id)
    const item = order.items[index]
    const quantity = item?.quantity || 0
    let remainingInventory: number | undefined = 0
    if (order.date) {
      remainingInventory = getProductInventory(
        product,
        order.date,
        order.items,
        products,
      )
    }
    const hasInventory =
      remainingInventory === undefined || remainingInventory > 0
    const hasNotReachedMaximum =
      !product.maximumPerOrder || quantity < product.maximumPerOrder
    const menu = menus?.find((m) => m.id === product.menuId)
    const time = menu?.pickupDates.find(pickupDateContainsISO(order.date))
    const hideSelectors =
      !menu?.pickupDates.length || menu?.disabled || (order.date && !time)
    const canAddItem = hasInventory && hasNotReachedMaximum
    return { item, index, quantity, canAddItem, hideSelectors }
  }, [order.items, order.date, product, products, menus])

  const decrease = () => {
    if (!order.date && promptTimeSelect) {
      promptTimeSelect()
    } else if (quantity > 1) {
      updateOrderItem(index, { ...item, quantity: quantity - 1 })
    } else if (quantity === 1) {
      removeItemFromOrder(index)
    }
  }

  const increase = () => {
    if (!order.date) {
      onIncrease?.()
      promptTimeSelect?.()
    } else if (!item && canAddItem) {
      addItemToOrder({
        name: product.name,
        menuId: product.menuId,
        price: product.price,
        taxRate: product.taxRate,
        productId: product.id,
        quantity: 1,
        options: [],
      })
    } else if (canAddItem) {
      updateOrderItem(index, { ...item, quantity: quantity + 1 })
    }
  }

  if (order.date && !canAddItem && !quantity) {
    return <>Sold Out</>
  }

  if (product.disabled || hideSelectors) {
    return <></>
  }

  return (
    <QuantitySelector
      quantity={quantity}
      onClickDecrease={decrease}
      onClickIncrease={increase}
    />
  )
}
