import { defaultTheme } from '@Pacific-Web-Services/component-library'

export const appTheme = {
  ...defaultTheme,
  fontFamily: `'Merriweather Sans', sans-serif`,
  colors: {
    ...defaultTheme.colors,
    secondary: '#2e7c87',
  },
  nav: {
    ...defaultTheme.nav,
    fontSize: `20px`,
    topOffset: `50px`,
  },
  product: {
    ...defaultTheme.product,
    minHeight: `145px`,
  },
}
