import {
  useOrder,
  usePublicMenusForLocation,
} from '@Pacific-Web-Services/component-library'
import { useMemo } from 'react'
import { verifyOrder } from '../utils/orderUtils'

export default function useVerifyOrder() {
  const { order } = useOrder()
  const locationId = order.location?.id!
  const { data: menus } = usePublicMenusForLocation(locationId, {
    enabled: !!locationId,
    //5 MINUTES
    staleTime: 5 * 60 * 1000,
  })
  return useMemo(() => {
    return verifyOrder(order, menus)
  }, [order, menus])
}
