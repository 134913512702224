import { useOrder } from '@Pacific-Web-Services/component-library'
import { useLocation } from 'react-router-dom'

//used in several place to turn off time selection for orders
//& prevent receipts/checkout from displaying times
export default function useIsHoliday() {
  const { pathname } = useLocation()
  const { order } = useOrder()
  // Wayfarer holiday menu id = 5
  // Wayfarer thanksgiving menu id = 1
  return false
  // return (
  //   pathname != '/tacos' &&
  //   order.items.some((item) => item.menuId != 9)
  // )
}
