import { DateTime, Interval } from 'luxon'
import { OrderItemDto } from 'order/dto/order-item.dto'
import { ProductThroughput } from 'product-throughput/product-throughput.entity'
import { ThroughputBucket } from 'product-throughput/throughput-bucket.entity'
import { ProductPublicDto } from 'product/dto/product-public.dto'

export function getProductInventory(
  product: ProductPublicDto,
  date: string,
  items?: OrderItemDto[],
  products?: ProductPublicDto[],
) {
  const dateTime = DateTime.fromISO(date, { setZone: true })
  const remainingAmounts = product.throughputs
    .filter(throughputContainsDate(dateTime))
    .map((throughput) => {
      let { amount } = throughput
      const bucket = throughput.buckets.find(bucketContainsDate(dateTime))
      if (bucket) {
        amount = bucket.amount
      }
      let quantityInOrder = 0
      if (items?.length && products) {
        const productIds = products
          .filter(productHasThroughput(throughput.id))
          .map((product) => product.id)
        quantityInOrder = items.reduce((quantity: number, item) => {
          if (productIds.includes(item.productId)) {
            return quantity + item.quantity
          }
          return quantity
        }, 0)
      }
      return amount - quantityInOrder
    })
  return remainingAmounts.length ? Math.min(...remainingAmounts) : undefined
}

function throughputContainsDate(dateTime: DateTime) {
  const dateISO = dateTime.toISODate()
  return (throughput: ProductThroughput) => {
    const { from, through } = throughput.validDates
    const interval = Interval.fromISO(`${from}/${through}`, {
      zone: dateTime.zone,
    })
    return (
      !from ||
      from === dateISO ||
      through === dateISO ||
      interval.contains(dateTime)
    )
  }
}

function bucketContainsDate(dateTime: DateTime) {
  return (bucket: ThroughputBucket) => {
    if (bucket.date) {
      return bucket.date === dateTime.toISODate()
    }
    if (bucket.start && bucket.end) {
      const start = DateTime.fromISO(bucket.start)
      const end = DateTime.fromISO(bucket.end)
      const interval = Interval.fromDateTimes(start, end)
      return interval.contains(dateTime)
    }
    return false
  }
}

function productHasThroughput(throughputId: number) {
  return (product: ProductPublicDto) =>
    product.throughputs.some((throughput) => throughput.id === throughputId)
}
