import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  StructuredModal,
  useOrder,
} from '@Pacific-Web-Services/component-library'
import styles from './PickupTime.module.css'
import { PickupDateDto } from 'menu/dto/pickup-date.dto'
import {
  combineDateAndTime,
  dateToLabel,
  dateToMilitaryTime,
  localTime,
} from '../utils/timeFunctions'
import usePickupDates from '../hooks/usePickupDates'
import PickupTimeSelector from './PickupTimeSelector'
import { DateTime } from 'luxon'
import { ProductPublicDto } from 'product/dto/product-public.dto'
import useMenus from '../Menus/useMenus'
import { getProductInventory } from '../utils/inventoryFunctions'
import Skeleton from 'react-loading-skeleton'
import useIsHoliday from '../hooks/useIsHoliday'

export default function PickupTimeView(props: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => void
  promptedBy?: ProductPublicDto
  isLoading?: boolean
  clearPromptedBy?: () => void
}) {
  const pickupDates = usePickupDates()
  if (props.isLoading) {
    return (
      <div
        className={styles.container}
        style={{ cursor: 'initial', justifyContent: 'flex-end' }}
      >
        <Skeleton width="100%" height={48} />
      </div>
    )
  }
  if (!pickupDates.length) {
    return (
      <div className={styles.container} style={{ cursor: 'initial' }}>
        <div className={styles.button}>
          We are not currently accepting orders
        </div>
      </div>
    )
  }
  return <PickupTimes {...props} pickupDates={pickupDates} />
}

function PickupTimes(props: {
  pickupDates: PickupDateDto[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => void
  promptedBy?: ProductPublicDto
  clearPromptedBy?: () => void
}) {
  const isHoliday = useIsHoliday()
  const { pickupDates, isOpen, setIsOpen, onClose, promptedBy } = props
  const { order, setPickupTime } = useOrder()
  const orderDate = order.date && DateTime.fromISO(order.date).toISODate()
  const orderTime = order.date && dateToMilitaryTime(order.date)
  const menus = useMenus()
  const timeZone = order.location?.timeZone
  const [date, setDate] = useState(orderDate || pickupDates[0]?.date || '')
  const [time, setTime] = useState(orderTime || pickupDates[0]?.times[0] || '')
  const onUpdate = () => {
    setPickupTime(combineDateAndTime(date, time, timeZone))
    onClose?.()
    setIsOpen(false)
  }
  const updateButton = (
    <div className={styles.updateButtonContainer}>
      <Button onClick={onUpdate} className={styles.updateButton}>
        Update
      </Button>
    </div>
  )
  const onRequestClose = () => {
    if (order.date) {
      const dateTime = DateTime.fromISO(order.date)
      setDate(dateTime.toISODate())
      setTime(dateToMilitaryTime(dateTime))
    }
    onClose?.()
    setIsOpen(false)
  }
  useEffect(() => {
    if (!order.date && promptedBy) {
      const menu = menus?.find((m) => m.id === promptedBy.menuId)
      let nextAvailableDate: string | undefined = undefined
      if (menu) {
        let i = 0
        while (i < menu.pickupDates.length && !nextAvailableDate) {
          const curDate = menu.pickupDates[i]
          let j = 0
          while (j < curDate.times.length && !nextAvailableDate) {
            const curTime = curDate.times[j]
            const iso = combineDateAndTime(
              curDate.date,
              curTime,
              order.location?.timeZone,
            )
            const inventory = getProductInventory(promptedBy, iso)
            if (inventory === undefined || inventory > 0) {
              nextAvailableDate = iso
            }
            j++
          }
          i++
        }
      }
      if (nextAvailableDate) {
        const dateTime = DateTime.fromISO(nextAvailableDate, { setZone: true })
        setDate(dateTime.toISODate())
        setTime(dateToMilitaryTime(dateTime))
        props.clearPromptedBy?.()
      }
    }
  }, [
    order.date,
    order.location?.timeZone,
    setDate,
    setTime,
    promptedBy,
    menus,
  ])
  return (
    <div
      className={styles.container}
      onClick={isOpen ? undefined : () => props.setIsOpen(true)}
    >
      <div className={styles.button}>
        {order.date
          ? pickupTimeLabel(order.date, isHoliday)
          : 'Please Select a Pickup Time'}
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      </div>
      <StructuredModal
        title="Pickup Time"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        footer={updateButton}
      >
        <p>When would you like to pick up your order?</p>
        {isHoliday && <p>Orders available for pickup from 9am-2pm.</p>}
        <PickupTimeSelector
          pickupDates={pickupDates}
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
        />
      </StructuredModal>
    </div>
  )
}

function pickupTimeLabel(date: string, isHoliday: boolean) {
  if (isHoliday) {
    return `Pickup for ${dateToLabel(date)}`
  }
  return `Pickup for ${dateToLabel(date)} at ${localTime(date)}`
}
