import { MenuPublicDto } from 'menu/dto/menu-public.dto'
import { CreateOrderDto } from 'order/dto/create-order.dto'
import { getProductInventory } from './inventoryFunctions'
import { menuToPickupDates, menuToProducts } from './menuReducer'
import { pickupDateContainsISO } from './timeFunctions'

type InvalidReasons = string[]

export function verifyOrder(order: CreateOrderDto, menus?: MenuPublicDto[]) {
  if (!menus || !order.date) {
    return []
  }
  const invalidReasons: InvalidReasons = []
  const pickupDates = menus.reduce(menuToPickupDates, [])
  const pickupDate = pickupDates.find(pickupDateContainsISO(order.date))
  if (!pickupDate) {
    return ['Pickup time no longer available']
  }
  const products = menus.reduce(menuToProducts, [])
  for (const item of order.items) {
    const product = products.find((p) => p.id === item.productId)
    if (
      !product ||
      product.disabled ||
      (product.maximumPerOrder && item.quantity > product.maximumPerOrder)
    ) {
      invalidReasons.push(`${item.name} no longer available`)
    } else {
      const menu = menus.find((m) => m.id === item.menuId)
      const menuDate = menu?.pickupDates.find(pickupDateContainsISO(order.date))
      const inventory = getProductInventory(
        product,
        order.date,
        order.items,
        products,
      )
      if (!menuDate || (typeof inventory === 'number' && inventory < 0)) {
        invalidReasons.push(`${item.name} not available for selected time`)
      }
    }
  }
  return invalidReasons
}
