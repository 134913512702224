import {
  Button,
  StructuredModal,
  useOrder,
} from '@Pacific-Web-Services/component-library'
import { OrderItemDto } from 'order/dto/order-item.dto'
import React, { useEffect, useState } from 'react'
import ModalSelector from './ModalSelector'
import styles from './Product.module.css'
import useProducts from './useProducts'

export default function ProductModal(props: {
  item?: OrderItemDto
  onClose: () => void
  onUpdate?: () => void
}) {
  const { item, onClose } = props
  const [quantity, setQuantity] = useState(0)
  const { order, removeItemFromOrder, updateOrderItem } = useOrder()
  const products = useProducts()
  const product = products?.find((p) => p.id === item?.productId)
  const onUpdate = () => {
    if (item && quantity !== item?.quantity) {
      const index = order.items.findIndex(
        (i) => i.productId === item?.productId,
      )
      if (quantity === 0) {
        removeItemFromOrder(index)
      } else {
        updateOrderItem(index, { ...item, quantity })
      }
    }
    props.onUpdate?.()
    onClose()
  }
  const footer = (
    <div className={styles.footer}>
      {item && product && (
        <ModalSelector
          product={product}
          quantity={quantity}
          setQuantity={setQuantity}
        />
      )}
      <Button onClick={onUpdate} className={styles.button}>
        Done
      </Button>
    </div>
  )
  useEffect(() => {
    if (item) {
      setQuantity(item.quantity)
    }
  }, [item, setQuantity])
  return (
    <StructuredModal
      title={product?.name}
      isOpen={!!item}
      onRequestClose={onClose}
      footer={footer}
    >
      <div className={styles.imageContainer}>
        <img
          src={product?.images?.[0]?.url}
          className={styles.image}
          alt={product?.description}
        />
      </div>
      <p>{product?.description}</p>
    </StructuredModal>
  )
}
