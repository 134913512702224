import {
  Button,
  StructuredModal,
  useOrder,
} from '@Pacific-Web-Services/component-library'
import React from 'react'
import useVerifyOrder from '../hooks/useVerifyOrder'
import styles from './Modal.module.css'

export default function InvalidOrderModal() {
  const invalidReasons = useVerifyOrder()
  const { clearOrder } = useOrder()
  const restartButton = (
    <div className={styles.buttonContainer}>
      <Button onClick={clearOrder} className={styles.button}>
        Restart Order
      </Button>
    </div>
  )
  return (
    <StructuredModal
      title="Your order is invalid"
      isOpen={!!invalidReasons.length}
      onRequestClose={clearOrder}
      footer={restartButton}
    >
      <h3>Reasons:</h3>
      {invalidReasons.map((s) => s)}
    </StructuredModal>
  )
}
