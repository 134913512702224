import { DateTime } from 'luxon'
import { PickupDateDto } from 'menu/dto/pickup-date.dto'

export function dateToMilitaryTime(dateTime: string | DateTime) {
  if (typeof dateTime === 'string') {
    return DateTime.fromISO(dateTime).toISOTime({
      suppressMilliseconds: true,
      includeOffset: false,
    })
  }
  return dateTime.toISOTime({
    suppressMilliseconds: true,
    includeOffset: false,
  })
}

export function compareISO(a: string, b: string) {
  const dtA = DateTime.fromISO(a)
  const dtB = DateTime.fromISO(b)
  return dtA.diff(dtB).toMillis()
}

export function localTime(time: string) {
  return DateTime.fromISO(time, { setZone: true }).toFormat('t')
}

// if this is passed a full ISO string it will always return
// the date in 'ccc, L/d' format
export function dateToLabel(date: string) {
  const today = DateTime.now()
  if (today.toISODate() === date) {
    return 'Today'
  }
  const tomorrow = today.plus({ days: 1 })
  if (tomorrow.toISODate() === date) {
    return 'Tomorrow'
  }
  const dateTime = DateTime.fromISO(date)
  return dateTime.toFormat('ccc, L/d')
}

export function combineDateAndTime(
  date: string,
  time: string,
  timeZone?: string,
) {
  return DateTime.fromISO(`${date}T${time}`, { zone: timeZone }).toISO()
}

export function pickupDateContainsISO(iso: string) {
  const dateTime = DateTime.fromISO(iso, { setZone: true })
  return (pickupDate: PickupDateDto) =>
    pickupDate.date === dateTime.toISODate() &&
    pickupDate.times.includes(dateToMilitaryTime(dateTime))
}
