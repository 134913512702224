import { useOrder } from '@Pacific-Web-Services/component-library'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'

const API_URL = process.env.REACT_APP_API_URL_BASE || 'http://localhost:3001'

export default function useHandleOrderSubmit() {
  const stripe = useStripe()
  const elements = useElements()
  const { order, clearOrder } = useOrder()
  const client = useQueryClient()
  const navigate = useNavigate()
  return useMutation<void, Error, React.FormEvent<HTMLFormElement>>(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if (!stripe || !elements) {
        throw new Error('Stripe failed to load.')
      }
      // @ts-ignore
      const createUrl = API_URL + '/order/create-order-public'
      const createOrderResponse = await fetch(createUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),
      })
      if (!createOrderResponse.ok) {
        throw new Error('Create order failed.')
      }
      const { client_secret, orderId, uuid } = await createOrderResponse.json()
      const stripeResponse = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement)!,
          billing_details: {
            address: {
              postal_code: order.customer.postalCode,
            },
          },
        },
      })
      if (stripeResponse.error) {
        throw new Error(stripeResponse.error.message)
      }
      if (stripeResponse.paymentIntent.status !== 'requires_capture') {
        throw new Error('Payment cannot be captured.')
      }
      const confirmUrl = API_URL + '/order/' + orderId + '/confirm-order'
      const confirmOrderReponse = await fetch(confirmUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!confirmOrderReponse.ok) {
        throw new Error('Confirm order failed.')
      }
      navigate('/confirm/' + uuid)
      clearOrder()
    },
    { onSuccess: () => client.invalidateQueries(['order']) },
  )
}
