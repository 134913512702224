import { MenuPublicDto } from 'menu/dto/menu-public.dto'
import { PickupDateDto } from 'menu/dto/pickup-date.dto'
import { SectionPublicDto } from 'menu/dto/section-public.dto'
import { ProductPublicDto } from 'product/dto/product-public.dto'
import { compareISO } from './timeFunctions'

function menuToProducts(products: ProductPublicDto[], menu: MenuPublicDto) {
  return products.concat(menu.sections.reduce(sectionToProducts, []))
}

const sectionToProducts = (
  products: ProductPublicDto[],
  section: SectionPublicDto,
) => products.concat(section.products)

function menuToPickupDates(dates: PickupDateDto[], menu: MenuPublicDto) {
  const newDates: PickupDateDto[] = []
  const dupIndices: number[] = []
  for (const menuDate of menu.pickupDates) {
    const dupIndex = dates.findIndex((d) => d.date === menuDate.date)
    if (dates[dupIndex]) {
      dupIndices.push(dupIndex)
      const times = menuDate.times
        .reduce(addUniqueTimes, dates[dupIndex].times)
        .sort(compareISO)
      newDates.push({ date: menuDate.date, times })
    } else {
      newDates.push({ ...menuDate, times: [...menuDate.times] })
    }
  }
  return dates
    .filter((d, i) => !dupIndices.includes(i))
    .concat(newDates)
    .sort((a, b) => compareISO(a.date, b.date))
}

function toPickupDateIntersections(
  dates: PickupDateDto[],
  menu: MenuPublicDto,
  currentIndex: number,
) {
  if (currentIndex === 0) {
    return [...menu.pickupDates]
  }
  const newDates: PickupDateDto[] = []
  for (const date of dates) {
    const menuDate = menu.pickupDates.find((d) => d.date === date.date)
    if (menuDate) {
      const newTimes = date.times.filter((t) => menuDate.times.includes(t))
      if (newTimes.length) {
        newDates.push({ date: date.date, times: newTimes })
      }
    }
  }
  return newDates
}

function addUniqueTimes(times: string[], time: string) {
  if (times.includes(time)) {
    return times
  }
  return times.concat(time)
}

export { menuToProducts, menuToPickupDates, toPickupDateIntersections }
