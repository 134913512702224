import React, { useEffect, useRef, useState } from 'react'
import {
  useOrder,
  OrderSummary,
  TipSelector,
} from '@Pacific-Web-Services/component-library'
import styles from './Checkout.module.css'
import PickupTimeView from '../PickupTime/PickupTimeView'
import InvalidOrderModal from '../Modals/InvalidOrderModal'
import { Navigate } from 'react-router-dom'
import CheckoutForms from './CheckoutForms'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { OrderItemDto } from 'order/dto/order-item.dto'
import ProductModal from '../Product/ProductModal'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY!, {
  stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
})

const stripeFont = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&display=swap',
    },
  ],
}

export default function CheckoutRoute() {
  const { order } = useOrder()
  const readyForCheckout = order.location && order.date && !!order.items.length
  if (!readyForCheckout) {
    return <Navigate to="/" replace={true} />
  }
  return <CheckoutView />
}
const CheckoutView = () => {
  const { order, setTip, removeItemFromOrder } = useOrder()
  const [editTime, setEditTime] = useState(false)
  const [editItem, setEditItem] = useState<OrderItemDto>()

  const tipRef = useRef<{ reset: () => void }>()

  useEffect(() => {
    tipRef.current?.reset()
    return () => {
      setTip(0)
    }
  }, [setTip])

  return (
    <div className={styles.container}>
      <PickupTimeView isOpen={editTime} setIsOpen={setEditTime} />
      <h3 className={styles.h3}>Pickup Location</h3>
      <p>
        {order.location?.name} <br />
        {order.location?.address.line1} <br />
      </p>
      <hr className={styles.hr} />
      <h3 className={styles.h3}>Order Summary</h3>
      <OrderSummary
        onEdit={(item: OrderItemDto, index: number) => {
          setEditItem(item)
        }}
        onRemove={(item, index) => {
          tipRef.current?.reset()
          removeItemFromOrder(index)
        }}
        order={order}
      />
      <hr className={styles.hr} />
      <h3 className={styles.h3}>Tip Amount</h3>
      <TipSelector ref={tipRef} onChangeTip={setTip} total={order.subtotal} />
      <hr className={styles.hr} />
      <Elements stripe={stripePromise} options={stripeFont}>
        <CheckoutForms />
      </Elements>
      <InvalidOrderModal />
      <ProductModal
        onUpdate={() => {
          tipRef.current?.reset()
        }}
        item={editItem}
        onClose={() => setEditItem(undefined)}
      />
    </div>
  )
}
