import { Button } from '@Pacific-Web-Services/component-library'
import React from 'react'
import ContactForm from './ContactForm'
import PaymentForm from './PaymentForm'
import styles from './Checkout.module.css'
import useHandleOrderSubmit from '../hooks/useHandleOrderSubmit'

export default function CheckoutForms() {
  const {
    mutate: handleSubmit,
    isLoading,
    isError,
    error,
  } = useHandleOrderSubmit()
  return (
    <form onSubmit={handleSubmit}>
      <ContactForm />
      <PaymentForm />
      {isError && <span className={styles.error}>{error.message}</span>}
      <Button
        type="submit"
        className={styles.submitButton}
        disabled={isLoading}
      >
        {isLoading ? '...Processing' : 'Place Order'}
      </Button>
    </form>
  )
}
