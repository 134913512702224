import { MenuPublicDto } from 'menu/dto/menu-public.dto'
import React from 'react'
import Banner from './Banner'
import styles from './Menus.module.css'

export default function MenuHeader({
  menu,
  id,
}: {
  menu: MenuPublicDto
  id?: string
}) {
  return (
    <div id={id}>
      {menu.bannerUrl && <Banner src={menu.bannerUrl} />}
      <div className={styles.headerText}>
        <h2>{menu.name}</h2>
        {menu.description &&
          menu.description
            .split('\n')
            .map((d) => <p className={styles.headerDescription}>{d}</p>)}
      </div>
    </div>
  )
}
