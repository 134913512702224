import { useAccount } from '@Pacific-Web-Services/component-library'

const accountId = process.env.REACT_APP_ACCOUNT_ID || 1

export function useWayfarerAccount() {
  return useAccount(+accountId, {
    staleTime: 6 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
  })
}
