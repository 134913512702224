import React from 'react'
import styles from './Header.module.css'
import Skeleton from 'react-loading-skeleton'
import { useWayfarerAccount } from '../hooks/useWayfarerAccount'

export default function Header() {
  const { data } = useWayfarerAccount()
  return (
    <div className={styles.container}>
      <a className={styles.logoLink} href="https://www.wayfarerbread.com/">
        {data ? (
          <img
            className={styles.logo}
            alt="Wayfarer Logo"
            src={data?.logoUrl}
          />
        ) : (
          <Skeleton height={80} width={240} />
        )}
      </a>
      <div className={styles.textLine}>
        <span className={styles.text}>San Diego</span>
        <span className={styles.text}>California</span>
        <span className={styles.text}>@wayfarer_bread</span>
      </div>
    </div>
  )
}
