import { Select, useOrder } from '@Pacific-Web-Services/component-library'
import { DateTime } from 'luxon'
import { PickupDateDto } from 'menu/dto/pickup-date.dto'
import React, { useMemo } from 'react'
import useIsHoliday from '../hooks/useIsHoliday'
import useLimitedProduct from '../hooks/useLimitedProduct'
import { formatPizzaTime, usePizzaInventory } from '../utils/clientFunctions'
import { dateToLabel, localTime } from '../utils/timeFunctions'
import styles from './PickupTime.module.css'

export default function PickupTimeSelector(props: {
  pickupDates: PickupDateDto[]
  date: string
  setDate: (date: string) => void
  time: string
  setTime: (time: string) => void
}) {
  const { pickupDates, date, setDate, time, setTime } = props
  const isHoliday = useIsHoliday()
  const { order } = useOrder()
  const productName = useLimitedProduct()
  const dateOptions = useMemo(() => {
    const dateOptions: any[] = []
    pickupDates.forEach((pickupDate, i, arr) => {
      const prev = arr[i - 1]
      const dayBefore = DateTime.fromISO(pickupDate.date)
        .minus({ days: 1 })
        .toISODate()
      if (prev && prev.date !== dayBefore) {
        dateOptions.push({
          value: '---' + i,
          label: '---',
          disabled: true,
        })
      }
      dateOptions.push({
        value: pickupDate.date,
        label: dateToLabel(pickupDate.date),
      })
    })
    return dateOptions
  }, [pickupDates])
  const pizzaInventory = usePizzaInventory(date, order.location!.timeZone)
  const timeOptions = useMemo(() => {
    const pickupDate = pickupDates.find((d) => d.date === date)
    return pickupDate?.times.map((t) => {
      if (Object.keys(pizzaInventory).includes(t)) {
        return {
          value: t,
          label: formatPizzaTime(t, pizzaInventory[t], productName),
        }
      }
      return {
        value: t,
        label: localTime(t),
      }
    })
  }, [date, pickupDates, pizzaInventory, productName])
  return (
    <div className={styles.selectContainer}>
      <Select
        options={dateOptions}
        onChange={(e) => setDate(e.target.value)}
        value={date}
        className={styles.select}
      />
      {!isHoliday && (
        <Select
          options={timeOptions || [{ value: 'error', label: 'error' }]}
          onChange={(e) => setTime(e.target.value)}
          value={time}
          className={styles.select}
        />
      )}
    </div>
  )
}
