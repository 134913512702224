import {
  OrderSummary,
  useOrderPublic,
} from '@Pacific-Web-Services/component-library'
import React, { useMemo } from 'react'
import { Navigate, NavLink, useParams } from 'react-router-dom'
import styles from './Checkout.module.css'
import { DateTime } from 'luxon'
import Skeleton from 'react-loading-skeleton'
import useIsHoliday from '../hooks/useIsHoliday'
import { CurrencyCode } from '../enums'

export function OrderAccepted() {
  const isHoliday = useIsHoliday()
  const { uuid } = useParams<{ uuid: string }>()
  const { data: order, isLoading } = useOrderPublic(uuid || '', {
    enabled: !!uuid,
  })
  const orderFormatted = useMemo(() => {
    if (!order) {
      return undefined
    }
    return {
      ...order,
      locationId: order.location.id,
      customer: { name: '', email: '', phone: '' },
      ...order?.orderTotal,
      items: order?.items?.map((item: any) => ({
        ...item,
        price: item.product.price,
        name: item.product.name,
      })),
      total: {
        amount: order?.orderTotal?.total,
        currency: CurrencyCode.USD,
      },
      subtotal: order?.orderTotal?.subtotal || 0,
      consumerFeeAmount: order.orderTotal.consumerFee || 0,
    }
  }, [order])

  if (!uuid) {
    return <Navigate to="/" replace={true} />
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Skeleton width="100%" height={50} />
      </div>
    )
  }

  if (!order) {
    return (
      <div className={styles.container}>
        <h3 className={styles.h3} style={{ textAlign: 'center' }}>
          Uh oh! We are unable to locate your order
        </h3>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.h3} style={{ textAlign: 'center' }}>
        Thank you for your order!
      </h3>
      <h3 className={styles.h3}>Pickup Time</h3>
      <p>
        {DateTime.fromISO(order.date, {
          zone: order.location.timeZone,
        }).toLocaleString(
          isHoliday ? DateTime.DATE_FULL : DateTime.DATETIME_MED_WITH_WEEKDAY,
        )}
        <br />
      </p>
      <h3 className={styles.h3}>Pickup Location</h3>
      <p>
        {order.location?.name} <br />
        {order.location?.address.line1} <br />
      </p>
      <hr className={styles.hr} />
      <h3 className={styles.h3}>Order Summary</h3>
      {orderFormatted && <OrderSummary order={orderFormatted} />}
      <h3
        className={styles.h3}
        style={{ textAlign: 'center', marginTop: '42px' }}
      >
        We look forward to seeing you!
      </h3>

      <NavLink to={'/'}>
        <p className={styles.back}>Back to Ordering</p>
      </NavLink>
    </div>
  )
}
